<template>
    <api-account-form></api-account-form>
</template>

<script>
import ApiAccountForm from "./form.vue";

export default {
    name: "create",

    components: {ApiAccountForm}
}
</script>

<style scoped>

</style>
